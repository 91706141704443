<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <confirmation-dialog
        @close="dialogDelete = false"
        @confirm="deleteItemConfirm"
        :dialog="dialogDelete"
        :dialogHeadline="deleteConfirmationHeadline"
      ></confirmation-dialog>
      <v-col cols="12">
        <div>
          <v-data-table
            v-model="selected"
            show-select
            :loading="loading"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :headers="headers"
            :items="posts"
            :disable-sort="true"
            class="elevation-0 pb-4 pt-4"
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-4 px-3">
                <v-text-field
                  outlined
                  v-model="search"
                  @input="searchPosts(1)"
                  append-icon="mdi-magnify"
                  :label="$t('lead.search')"
                  hide-details
                ></v-text-field>
                <v-spacer />
                <v-autocomplete
                  v-model="parentId"
                  :items="getMembersList"
                  item-text="company_name"
                  item-value="id"
                  :label="$t('associate.member')"
                  outlined
                  hide-details
                  :value="parentId"
                  @change="initialize(1)"
                  return-value
                ></v-autocomplete>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!selected.length"
                  v-if="managePosting"
                  color="primary"
                  @click="dialogDelete = true"
                  elevation="4"
                >
                  {{ $t("posting.delete") }}
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | datefilter }}
            </template>
            <template v-if="managePosting" v-slot:item.actions="{ item }">
              <v-btn color="error" class="red-color" :ripple="false" icon>
                <v-icon
                  medium
                  @click="
                    deletedPostItem = item;
                    dialogDelete = true;
                  "
                >
                  mdi-delete</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-if="posts.length > 0"
              v-model="meta.current_page"
              :length="meta.last_page"
              @input="initialize"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { showSnackbar } from "../eventbus/action.js";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import { MANAGE_POSTING } from "@/constants/permission";
import _ from "lodash";
export default {
  name: "Posting",
  components: { ConfirmationDialog },
  data() {
    return {
      parentId: "",
      deleteConfirmationHeadline: this.$t("posting.deleteHeadline"),
      managePosting: false,
      dialog: false,
      dialogDelete: false,
      loading: false,
      itemsPerPage: 50,
      page: 1,
      search: "",
      headers: [
        {
          text: this.$t("posting.date"),
          align: "start",
          value: "created_at",
          width: "20%",
        },
        {
          text: this.$t("posting.message"),
          value: "message",
          width: "40%",
        },
        {
          text: this.$t("posting.companyName"),
          value: "owner.user_detail.company_name",
          width: "20%",
        },
        {
          text: this.$t("posting.actions"),
          value: "actions",
          width: "10%",
        },
      ],
      deletedPostItem: {},
      selected: [],
    };
  },

  mounted() {
    this.initialize(1);
  },

  computed: {
    ...mapGetters({
      members: "members/getMembersList",
      posts: "posting/getPosting",
      meta: "posting/getPostingMeta",
      user: "user/getUser",
    }),

    getMembersList() {
      return [
        {
          id: "",
          company_name: this.$t("posting.allMembers"),
        },
        ...this.members,
      ];
    },
  },
  created() {
    this.initialize(1);
  },

  methods: {
    ...mapActions({
      getMembers: "members/getMembersList",
      getPosts: "posting/getPosting",
      deletePost: "posting/deletePosting",
      setHeaderMenuItems: "common/setHeaderMenuItems",
    }),
    async initialize(page) {
      this.page = page;
      let menuItems = [
        {title: this.$t("commonLayout.private"), path: "/pages/private/posting"},
        {title: this.$t("commonLayout.public"), path: "/pages/public/posting"},
      ];
      const userPermission = this.user.permission.map((val) => val.name);
      this.managePosting = userPermission.includes(MANAGE_POSTING);
      this.loading = true;
      this.setHeaderMenuItems(menuItems);
      try {
        let ownerIdfilter = {};
        if (this.parentId && this.parentId !== "") {
          ownerIdfilter = { owner_id: this.parentId };
        }
        await this.getMembers();
        await this.getPosts({
          search: this.search,
          page: this.page,
          ...ownerIdfilter,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    searchPosts: _.debounce(function (page) {
      console.log(this.selected);
      this.initialize(page);
    }, 500),

    async deleteItemConfirm() {
      let deleteItemsIds = this.selected.map((val) => val.id);
      if (this.deletedPostItem.id) {
        deleteItemsIds = [this.deletedPostItem.id];
      }
      if (deleteItemsIds.length > 0) {
        this.loading = true;
        try {
          await this.deletePost({
            post_ids: deleteItemsIds,
          });
          this.initialize(1);
          this.dialogDelete = false;
          deleteItemsIds = [];
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
        } finally {
          this.deletedPostItem = {};
          this.loading = false;
          this.selected = [];
        }
      }
    },
  },
  destroyed() {
    this.setHeaderMenuItems([]);
  },
};
</script>
